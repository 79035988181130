<template>
  <dash-page-new>
    <template #pre_header.wide>
      <div style="width: 100%; position:relative;" class=" px-4 py-2">
        <h5 class="text-center">   {{ $t('avalon.homepage.blank.title') }}</h5>
      </div>
    </template>

    <template #default>
      <div
          class="
         d-flex
         fill-height
         justify-center
         align-center
         pb-16 pt-8 py-6" >

        <v-sheet  width="600">

          <div class="d-flex justify-center">
            <v-icon size="80" :color="wsDARKLIGHT" >mdi-palette</v-icon>
          </div>

          <h3 style="font-size: 22px; font-weight: 700" class="text-center  mt-6">
            {{ $t('avalon.homepage.blank.title') }}
          </h3>
          <h4  class="text-center  mb-6">
            {{ $t('avalon.homepage.blank.text') }}
          </h4>


        </v-sheet>

      </div>
    </template>




  </dash-page-new>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "avalonBlankHomepage",
  props : {
    language : {
      type : String
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang'])
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>